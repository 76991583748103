import connection from "../connection";
import gql from "graphql-tag";
import { print } from "graphql";

const allCurrenciesList = gql`
  query getCurrencies($pageSize: Int) {
    currencies(first: $pageSize) {
      edges {
        node {
          code
        }
      }
    }
  }
`;

export default {
  getAll() {
    return connection.graph({
      query: print(allCurrenciesList),
      variables: { pageSize: 1000 },
    });
  },
};
